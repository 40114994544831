import React from "react"
import Button from "../components/buttons"

class Intro extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
    }
  }

  render() {
    const content = this.state.content
    const bgImg = this.state.content.img.localFile.childImageSharp.fluid.src
    const bgImgMob = this.state.content.img_mobile.localFile.childImageSharp
      .fluid.src

    return (
      <section
        id="intro"
        className="black"
        style={bgImg ? { backgroundImage: `url(${bgImg})` } : ""}
      >
        {bgImgMob && (
          <img
            className="intro__bg-mobile"
            src={bgImgMob}
            alt="Businessman putting forward his suggestions to colleagues. Startup business team on meeting in modern bright office."
          />
        )}
        <div className="grid">
          <div className="row">
            <div className="col lg-5-12 md-6-12 sm-7-12">
              <div className="intro__content">
                <h1 dangerouslySetInnerHTML={{ __html: content.title }}></h1>
                {content.btn.title && content.btn.url && (
                  <Button
                    title={content.btn.title}
                    url="text"
                    classlist="btn btn--oval btn--oval--green"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Intro
