import React from "react"

class Roadmap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
    }
  }

  devidePoints(points) {
    const devidedPoints = []
    const leftPoints = []
    const rightPoints = []

    for (let i = 0; i < points.length; i++) {
      points[i].counter = i + 1
      if (i % 2 === 0) {
        leftPoints.push(points[i])
      } else {
        rightPoints.push(points[i])
      }
    }
    devidedPoints.push(leftPoints)
    devidedPoints.push(rightPoints)
    return devidedPoints
  }

  render() {
    const content = this.state.content

    const devidedPoints = this.devidePoints(content.points)

    let pointsCounter = 0
    return (
      <section id="roadmap" className="black">
        <div className="grid grid--no-gutter">
          {(content.title || content.subtitle) && (
            <div className="row">
              <div className="col lg-8-12 lg-push-left-2-12">
                <div className="section__title">
                  {content.title && (
                    <h3
                      className="white"
                      dangerouslySetInnerHTML={{ __html: content.title }}
                    />
                  )}
                  {content.subtitle && (
                    <p
                      className="bigger white"
                      dangerouslySetInnerHTML={{ __html: content.subtitle }}
                    />
                  )}
                </div>
              </div>
              <div className="col xs-12-12">
                <div className="points points--desktop">
                  {content.points.map(point => {
                    pointsCounter++
                    return (
                      <div
                        className={`point point--${pointsCounter} ${
                          pointsCounter % 2 === 0 ? "point--down" : "point--up"
                        } ${
                          point.highlighted_point === true
                            ? " point--highlighted"
                            : ""
                        }`}
                        key={`point-${pointsCounter}`}
                      >
                        {point.text && (
                          <div
                            className="point__text point__text--main"
                            dangerouslySetInnerHTML={{ __html: point.text }}
                          ></div>
                        )}
                        {point.outlined_text && (
                          <div
                            className="point__text point__text--outlined"
                            dangerouslySetInnerHTML={{
                              __html: point.outlined_text,
                            }}
                          ></div>
                        )}
                        <div className={`point__counter`}>
                          <span>{pointsCounter}</span>
                        </div>
                      </div>
                    )
                  })}
                  <div className="points__separator" />
                </div>
                {devidedPoints && (
                  <div className="points points--tablet">
                    <div className="points__left">
                      {devidedPoints[0].map(point => {
                        return (
                          <div
                            className={`point point--${point.counter} ${
                              point.highlighted_point === true
                                ? " point--highlighted"
                                : ""
                            }`}
                            key={`point-${point.counter}`}
                          >
                            {point.text && (
                              <div
                                className="point__text point__text--main"
                                dangerouslySetInnerHTML={{ __html: point.text }}
                              ></div>
                            )}
                            {point.outlined_text && (
                              <div
                                className="point__text point__text--outlined"
                                dangerouslySetInnerHTML={{
                                  __html: point.outlined_text,
                                }}
                              ></div>
                            )}
                            <div className={`point__counter`}>
                              <span>{point.counter}</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="points__right">
                      {devidedPoints[1].map(point => {
                        return (
                          <div
                            className={`point point--${point.counter} } ${
                              point.highlighted_point === true
                                ? " point--highlighted"
                                : ""
                            }`}
                            key={`point-${point.counter}`}
                          >
                            {point.text && (
                              <div
                                className="point__text point__text--main"
                                dangerouslySetInnerHTML={{ __html: point.text }}
                              ></div>
                            )}
                            {point.outlined_text && (
                              <div
                                className="point__text point__text--outlined"
                                dangerouslySetInnerHTML={{
                                  __html: point.outlined_text,
                                }}
                              ></div>
                            )}
                            <div className={`point__counter`}>
                              <span>{point.counter}</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="points__separator" />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    )
  }
}

export default Roadmap
