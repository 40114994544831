import React from "react"
import Button from "../components/buttons"

class Cta extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
    }
  }

  render() {
    const content = this.state.content
    return (
      <section id="cta" className="green" name="pricing">
        <div className="grid">
          {content.title && (
            <div className="section__title cta__part">
              <h3
                className="white"
                dangerouslySetInnerHTML={{ __html: content.title }}
              />
            </div>
          )}
          {content.subtitle && (
            <p
              className="bigger white cta__part"
              dangerouslySetInnerHTML={{ __html: content.subtitle }}
            />
          )}
          {content.button_title && (
            <Button
              title={content.button_title}
              url="contact"
              classlist="btn btn--oval btn--oval--white cta__part"
            />
          )}
        </div>
      </section>
    )
  }
}

export default Cta
