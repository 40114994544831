import React from "react"
import ContactForm from "../components/form"
import { StaticQuery, graphql } from "gatsby"

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
      successMsg: this.props.data.wordpressAcfOptions.options.misc,
    }
  }

  render() {
    const content = this.state.content

    return (
      <section id="contact">
        <div className="grid">
          <div className="row">
            <div className="col lg-4-12 lg-push-left-4-12 md-6-12 md-push-left-3-12 sm-8-12 sm-push-left-2-12">
              {content.title && (
                <div className="section__title">
                  <h3
                    className="form-hide"
                    dangerouslySetInnerHTML={{ __html: content.title }}
                  />
                </div>
              )}
              <ContactForm successInfo={this.state.successMsg} />
              <div className="contact__footnote form-hide contact__part">
                <span>
                  Siųsdami savo kontaktus Jūs sutinkate su mūsų{" "}
                  <a target="_blank" href="/privacy-policy">
                    privatumo politika
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            misc {
              contact_form_success_message
              contact_form_reply_notice
              form_repeat_button_title
            }
          }
        }
      }
    `}
    render={data => <Contact data={data} {...props} />}
  />
)
