import React from "react"
import { graphql, StaticQuery } from "gatsby"
import MainLayout from "../layout/mainLayout"
import Intro from "../layout/intro"
import Text from "../layout/text"
import Icons from "../layout/icons"
import Offer from "../layout/offer"
import Roadmap from "../layout/roadmap"
import Cta from "../layout/cta"
import Testimonials from "../layout/testimonials"
import News from "../layout/news"
import Faq from "../layout/faq"
import Contact from "../layout/contact"
import Footer from "../layout/footer"

class FrontPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      seoData: this.props.data.wordpressPage.acf.seo_settings,
      introContent: this.props.data.wordpressPage.acf.intro,
      textContent: this.props.data.wordpressPage.acf.text,
      offerContent: this.props.data.wordpressPage.acf.offer,
      roadmapContent: this.props.data.wordpressPage.acf.roadmap,
      iconsContent: this.props.data.wordpressPage.acf.icons,
      ctaContent: this.props.data.wordpressPage.acf.cta,
      testimonialContent: this.props.data.wordpressPage.acf.testimonials,
      newsContent: this.props.data.wordpressPage.acf.news,
      faqContent: this.props.data.wordpressPage.acf.faq,
      formContent: this.props.data.wordpressPage.acf.form,
    }
  }

  render() {
    return (
      <MainLayout
        footerless={false}
        headerTheme="light"
        navType="scroll"
        pageID={2}
        seoData={this.state.seoData}
        page="front"
      >
        <Intro content={this.state.introContent} />
        {(this.state.textContent.title || this.state.textContent.text) && (
          <Text content={this.state.textContent} />
        )}
        {this.state.iconsContent.blocks.length > 0 && (
          <Icons content={this.state.iconsContent} />
        )}
        {this.state.offerContent.blocks.length > 0 && (
          <Offer content={this.state.offerContent} />
        )}
        {this.state.roadmapContent.points.length > 0 && (
          <Roadmap content={this.state.roadmapContent} />
        )}
        {(this.state.ctaContent.title ||
          this.state.ctaContent.subtitle ||
          this.state.ctaContent.button_title) && (
          <Cta content={this.state.ctaContent} />
        )}
        {this.state.testimonialContent.testimonials.length > 0 && (
          <Testimonials content={this.state.testimonialContent} />
        )}
        {(this.state.newsContent !== undefined &&
          this.state.newsContent.news.length) > 0 && (
          <News content={this.state.newsContent} />
        )}
        {this.state.faqContent.faq_.length > 0 && (
          <Faq content={this.state.faqContent} />
        )}
        <Contact content={this.state.formContent} />
        {this.state.footerContent && (
          <Footer content={this.state.footerContent} />
        )}
      </MainLayout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressPage(wordpress_id: { eq: 2 }) {
          acf {
            intro {
              title
              btn {
                url
                target
                title
              }
              img {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 891, quality: 80) {
                      src
                    }
                  }
                }
              }
              img_mobile {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 584, quality: 80) {
                      src
                    }
                  }
                }
              }
            }
            text {
              title
              text
            }
            offer {
              title
              blocks {
                title
                text
                btn {
                  title
                  url
                  target
                }
                img {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 616, quality: 80) {
                        src
                      }
                    }
                  }
                }
              }
            }
            roadmap {
              title
              subtitle
              points {
                highlighted_point
                text
                outlined_text
              }
            }
            icons {
              title
              button_title
              blocks {
                icon
                text
              }
            }
            cta {
              title
              subtitle
              button_title
            }
            testimonials {
              title
              testimonials {
                logo_not_svg {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 200, quality: 100) {
                        src
                      }
                    }
                  }
                }
                name
                position
                text
              }
            }
            # news {
            #   title
            #   news {
            #     date
            #     link
            #     title
            #     excerpt
            #     img {
            #       localFile {
            #         childImageSharp {
            #           fluid(maxWidth: 600, quality: 100) {
            #             src
            #           }
            #         }
            #       }
            #     }
            #   }
            # }
            faq {
              title
              faq_ {
                question
                answer
              }
            }
            form {
              title
            }
            seo_settings {
              meta_title
              meta_description
              meta_title_fb
              meta_description_fb
              meta_title_tw
              meta_description_tw
            }
          }
        }

        wordpressAcfOptions {
          options {
            not_found {
              title
              button_title
            }
          }
        }
      }
    `}
    render={(data, pageContext) => (
      <FrontPage data={data} pageContext={pageContext} {...props} />
    )}
  />
)
