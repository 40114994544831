import React from "react"
import Swiper from "react-id-swiper"

class Testimonials extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
    }
  }

  render() {
    const swiperParams = {
      spaceBetween: 25,
      slidesPerView: 3,
      watchOverflow: true,
      pagination: {
        el: ".testimonials__pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1025: {
          slidesPerView: 3,
        },
        569: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        }
      },
    }

    const content = this.state.content
    let testimonialCounter = 0
    return (
      <section id="testimonials">
        <div className="grid">
          {content.title && (
            <div className="section__title">
              <h3 dangerouslySetInnerHTML={{ __html: content.title }} />
            </div>
          )}
          <Swiper {...swiperParams}>
            {content.testimonials.map(testimonial => {
              const testimonialLogo =
                testimonial.logo_not_svg.localFile.childImageSharp.fluid.src

              testimonialCounter++
              return (
                <div
                  className="testimonial card"
                  key={`testimonial-${testimonialCounter}`}
                >
                  {testimonialLogo && (
                    <div className="testimonial__logo">
                      <img
                        src={testimonialLogo}
                        alt="Testimonial author logo."
                      />
                    </div>
                  )}
                  <div
                    className="testimonial__text"
                    dangerouslySetInnerHTML={{ __html: testimonial.text }}
                  />
                  {(testimonial.name || testimonial.position) && (
                    <div className="testimonial__credentials">
                      {testimonial.name && (
                        <span
                          className="testimonial__credentials__name"
                          dangerouslySetInnerHTML={{ __html: testimonial.name }}
                        />
                      )}
                      {testimonial.position && (
                        <span
                          className="testimonial__credentials__position"
                          dangerouslySetInnerHTML={{
                            __html: `, ${testimonial.position}`,
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              )
            })}
          </Swiper>
        </div>
      </section>
    )
  }
}

export default Testimonials
