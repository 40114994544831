import React from "react"
import { Link } from "react-scroll"

class BtnDefault extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: this.props.title,
      url: this.props.url,
      target: this.props.target,
      scroll: this.props.scroll,
      classList: this.props.classlist,
    }
  }

  render() {
    return (
      <Link
        to={this.state.url}
        smooth={true}
        duration={500}
        offset={-100}
        className={this.state.classList}
      >
        <span>{this.state.title}</span>
      </Link>
    )
  }
}

export default BtnDefault
