import React from "react"
import gsap from "gsap"

class Faq extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
      currentAcc: null,
      isOpen: false,
    }
  }

  closeAccordions() {
    gsap.to(".accordion-pair:not(.active) .accordion-pair__body", 0.3, {
      maxHeight: 0,
    })
  }

  clearAccordionActive() {
    Array.from(document.querySelectorAll(".accordion-pair")).forEach(el => {
      el.classList.remove("active")
    })
  }

  openAccordion(e) {
    this.clearAccordionActive()
    const currentAcc = e.target.closest(".accordion-pair")

    if (currentAcc === this.state.currentAcc && this.state.isOpen === true) {
      this.closeAccordions()
      this.setState({
        isOpen: false,
      })
    } else {
      currentAcc.classList.add("active")

      const currentAccBody = currentAcc.childNodes[1]

      this.closeAccordions()

      gsap.to(currentAccBody, 0.4, {
        maxHeight: currentAccBody.scrollHeight,
        ease: "Power2.easeInOut",
      })

      this.setState({
        currentAcc: currentAcc,
        isOpen: true,
      })
    }
  }

  render() {
    const content = this.state.content
    let faqCounter = 0
    return (
      <section id="faq" className="accordion" name="faq">
        <div className="grid">
          {content.title && (
            <div className="section__title">
              <h3 dangerouslySetInnerHTML={{ __html: content.title }} />
            </div>
          )}
          <div className="row">
            {content.faq_.map(pair => {
              faqCounter++
              return (
                <div
                  className="col lg-10-12 lg-push-left-1-12"
                  key={`faq-${faqCounter}`}
                >
                  <div className="accordion-pair-container">
                    <div
                      className="accordion-pair card"
                      onClick={e => {
                        this.openAccordion(e)
                      }}
                      onKeyDown={e => {
                        this.openAccordion(e)
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      <div className="accordion-pair__head">
                        <p
                          className="bigger"
                          dangerouslySetInnerHTML={{ __html: pair.question }}
                        />
                      </div>
                      <div className="accordion-pair__body">
                        <article
                          dangerouslySetInnerHTML={{ __html: pair.answer }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}

export default Faq
