import React from "react"
import Swiper from "react-id-swiper"

class News extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
    }
  }

  render() {
    const content = this.state.content

    const swiperParams = {
      spaceBetween: 24,
      slidesPerView: 4,
      pagination: {
        el: ".news__pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        1025: {
          slidesPerView: 4,
        },
        769: {
          slidesPerView: 3,
        },
        569: {
          slidesPerView: 2,
        },
        0: {
          slidesPerView: 1,
        },
      },
    }

    let newsCounter = 0

    return (
      <section id="news" className="appear">
        <div className="grid grid--no-gutter">
          {content.title && (
            <div className="section__title">
              <h3 dangerouslySetInnerHTML={{ __html: content.title }} />
            </div>
          )}
          <Swiper {...swiperParams}>
            {content.news.map(item => {
              const itemImg = item.img.localFile.childImageSharp.fluid.src
              newsCounter++
              return (
                <a
                  className="news__item card"
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={`news-item-${newsCounter}`}
                >
                  <div
                    className="news__item__img"
                    style={{ backgroundImage: `url(${itemImg})` }}
                  />
                  <div className="news__item__content">
                    {item.date && (
                      <span
                        className="news__item__date"
                        dangerouslySetInnerHTML={{ __html: item.date }}
                      />
                    )}
                    <p
                      className="bigger news__item__title"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    {item.excerpt && (
                      <p
                        className="news__item__excerpt"
                        dangerouslySetInnerHTML={{ __html: item.excerpt }}
                      />
                    )}
                    <span className="btn btn--uppercase btn--uppercase--blue">
                      Read more
                    </span>
                  </div>
                </a>
              )
            })}
          </Swiper>
        </div>
      </section>
    )
  }
}

export default News
