import React from "react"

class Text extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
    }
  }

  render() {
    const content = this.state.content

    return (
      <section id="text" className="black" name="text">
        <div className="grid">
          <div className="row">
            {content.title && (
              <div className="col lg-6-12 md-8-12 sm-10-12">
                <h2
                  className="white underlined appear"
                  dangerouslySetInnerHTML={{ __html: content.title }}
                />
              </div>
            )}
            {content.text && (
              <div className="col lg-8-12 md-10-12">
                <article
                  className="white appear"
                  dangerouslySetInnerHTML={{ __html: content.text }}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default Text
