import React from "react"
import Button from "../components/buttons"

class Offer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
    }
  }

  render() {
    const content = this.props.content

    let blockCount = 0

    return (
      <section id="offer" name="products">
        <div className="grid">
          {content.title && (
            <div className="section__title section__title--center">
              <h2 dangerouslySetInnerHTML={{ __html: content.title }} />
            </div>
          )}
          {content.blocks && (
            <div className="offer__blocks">
              {content.blocks.map(block => {
                blockCount++
                return (
                  <div
                    className="offer__block appear"
                    key={`block-${blockCount}`}
                  >
                    {(block.title || block.text) && (
                      <div className="offer__block__text">
                        {block.title && (
                          <h3
                            dangerouslySetInnerHTML={{ __html: block.title }}
                          />
                        )}
                        {block.text && (
                          <article
                            dangerouslySetInnerHTML={{ __html: block.text }}
                          />
                        )}
                        {block.btn.title && (
                          <div className="block__text__cta">
                            <Button
                              title={block.btn.title}
                              url="faq"
                              classlist="btn btn--oval btn--oval--green"
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {block.img && (
                      <div className="offer__block__img img-box">
                        {block.title && (
                          <h3
                            className="offer__block__title-mobile"
                            dangerouslySetInnerHTML={{ __html: block.title }}
                          />
                        )}
                        <img
                          src={block.img.localFile.childImageSharp.fluid.src}
                          alt={block.title}
                        />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </section>
    )
  }
}

export default Offer
