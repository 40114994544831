import React from "react"
import Button from "../components/buttons"
import bolt from "../assets/img/Icons/bolt.svg"
import checklist from "../assets/img/Icons/checklist.svg"
import cloud from "../assets/img/Icons/cloud.svg"
import door from "../assets/img/Icons/door.svg"
import hardDrive from "../assets/img/Icons/hard-drive.svg"
import institution from "../assets/img/Icons/institution.svg"
import money from "../assets/img/Icons/money.svg"

class Icons extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      content: this.props.content,
    }
  }

  groupIcons() {
    const icons = {
      bolt: bolt,
      checklist: checklist,
      cloud: cloud,
      door: door,
      hardDrive: hardDrive,
      institution: institution,
      money: money,
    }

    return icons
  }

  render() {
    const content = this.state.content
    const icons = this.groupIcons()

    let blockCounter = 0
    return (
      <section id="icons" className="black black--shaped" name="features">
        <div className="grid">
          <div className="row row--ver-stretch">
            <div className="col lg-3-12 md-4-12 xs-6-12">
              {content.title && (
                <div className="icons__title">
                  <h3
                    className="white"
                    dangerouslySetInnerHTML={{ __html: content.title }}
                  />
                </div>
              )}
            </div>
            {content.blocks.map(block => {
              blockCounter++
              return (
                <div className="col lg-3-12 md-4-12 xs-6-12" key={`block-${blockCounter}`}>
                  <div className="icons__block">
                    <div className="icons__block__img">
                      <img
                        src={icons[block.icon]}
                        alt={`Icon of ${block.icon}`}
                      />
                    </div>
                    <div
                      className="icons__block__text"
                      dangerouslySetInnerHTML={{ __html: block.text }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          {content.button_title && (
            <div className="icons__cta">
              <Button
                title={content.button_title}
                url="contact"
                scroll={true}
                classlist="btn btn--oval btn--oval--green"
              />
            </div>
          )}
        </div>
      </section>
    )
  }
}

export default Icons
