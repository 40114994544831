import React from "react"
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"

const hideForm = values => {
  document.querySelector(".form__success").classList.add("active")
  document.querySelector(".form__success__email").textContent = values.email
  Array.from(document.querySelectorAll(".form-hide")).forEach(el => {
    el.classList.add("hidden")
  })
}

const showForm = () => {
  document.querySelector(".form__success").classList.remove("active")
  Array.from(document.querySelectorAll(".form-hide")).forEach(el => {
    el.classList.remove("hidden")
  })
}

const ContactForm = props => {
  const successMsg = props.successInfo.contact_form_success_message
  const replyNotice = props.successInfo.contact_form_reply_notice
  const againBtnTitle = props.successInfo.form_repeat_button_title

  return (
    <Formik
      initialValues={{
        firstName: "",
        companyName: "",
        phone: "",
        email: "",
        message: "",
      }}
      validate={values => {
        const errors = {}
        // Email
        if (!values.email) {
          errors.email = "Nurodykite el. paštą."
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = "Atrodo, kad el. paštas neteisingas."
        }
        // Name
        if (!values.firstName) {
          errors.firstName = "Nurodykite vardą."
        }
        // Message
        if (!values.message) {
          errors.message = "Ką nors mums parašykite."
        }

        return errors
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(() => {
          axios
            .post(
              "https://cms.inventi.io/wp-json/handleForm/v1/form",
              JSON.stringify(values, null, 0)
            )
            .then(response => {
              if (response.status === 200) {
                resetForm()
                hideForm(values)

                typeof window !== "undefined" &&
                  window.dataLayer.push({
                    event: "formSubmit",
                    eventCategory: "Contact form",
                    eventAction: "Submit",
                    eventLabel: "Successful",
                  })
              }
            })
            .catch(error => alert(error))
          setSubmitting(false)
        }, 400)
      }}
    >
      {({ isSubmitting, errors, status, props, touched, values }) => (
        <Form>
          <label htmlFor="firstName" className="contact__part">
            <span className="required">Jūsų vardas:</span>
            <Field
              type="text"
              name="firstName"
              className={errors.firstName && touched.firstName ? "invalid" : ""}
            />
            <ErrorMessage
              name="firstName"
              component="div"
              className="error-message"
            />
          </label>

          <label htmlFor="companyName" className="contact__part">
            <span>Jūsų įmonė:</span>
            <Field type="text" name="companyName" />
          </label>

          <label htmlFor="phone" className="contact__part">
            <span>Telefono nr:</span>
            <Field type="tel" name="phone" />
          </label>

          <label htmlFor="email" className="contact__part">
            <span className="required">El. paštas:</span>
            <Field
              type="email"
              name="email"
              className={errors.email && touched.email ? "invalid" : ""}
            />
            <ErrorMessage
              name="email"
              component="div"
              className="error-message"
            />
          </label>

          <label htmlFor="message" className="contact__part">
            <span className="required">Žinutė:</span>
            <Field
              component="textarea"
              name="message"
              className={errors.message && touched.message ? "invalid" : ""}
            />
            <ErrorMessage
              name="message"
              component="div"
              className="error-message"
            />
          </label>

          <button
            className="btn btn--oval btn--oval--green contact__part"
            type="submit"
            disabled={isSubmitting}
          >
            <span>Siųsti</span>
          </button>

          {successMsg && (
            <div className="form__success">
              <div className="form__success__icon" />
              <h4 dangerouslySetInnerHTML={{ __html: successMsg }} />
              <p>
                {replyNotice}{" "}
                <span className="form__success__email">{values.email}</span>
              </p>
              {againBtnTitle && (
                <button
                  className="btn btn--oval btn--oval--green"
                  type="button"
                  onClick={() => showForm()}
                >
                  <span dangerouslySetInnerHTML={{ __html: againBtnTitle }} />
                </button>
              )}
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
